import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OUTRO_TIMEOUT } from "../utils/constants";
function Outro() {
  const [step, setStep] = React.useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setStep((step) => {
        if (step === 1) navigate("/");
        return step + 1;
      });
    }, OUTRO_TIMEOUT);

    return () => {
      clearTimeout(timeOut);
    };
  });
  return (
    <section className="outro-container">
      {step == 0 && (
        <>
          <div>
            <img src="/assets/state_four.png" alt="Model" />
          </div>
          <div>
            <img src="/assets/Outro.png" alt="Game Guidelines" />
          </div>
        </>
      )}
      {step == 1 && (
        <div className="rights-container">
          <p>
            Adverse events should also be reported to Allergan Aesthetics at
            [productsurveillance_eame@abbvie.com]
          </p>
          <p>AE Reporting: To report any side effects(s)</p>
          <p>
            In the event of any comments on the material(s) presented, please
            contact The Saudi Food & Drug Authority (SFDA) on the unified number
            19999.
          </p>
          <p>
            To report any side effects(s) Saudi Arabia: The National
            Pharmacovigilance Centre (NPC)- SFDA Call Center: 19999- E-mail:
            npc.drug@sfda.gov.sa website:
            <a href="https://ade.sfda.gov.sa">https://ade.sfda.gov.sa</a>
          </p>
        </div>
      )}
    </section>
  );
}

export default Outro;
