import { COLLECTION_NAME } from "./constants";
import { db } from "./firebase-config";
import "firebase/database";
import { User } from "./types";

export const addUser = async (name: string, email: string) => {
  const userId = db.collection(COLLECTION_NAME).doc().id;
  const userContent = {
    name,
    email,
    createdAt: new Date().toISOString(),
  };
  await db.collection(COLLECTION_NAME).doc(userId).set(userContent);
  return { id: userId, ...userContent };
};

export const getAllUsers = async (): Promise<User[]> => {
  const usersDocs = (await db.collection(COLLECTION_NAME).get()).docs;
  const users = usersDocs.map((doc) => {
    const data = doc.data();

    return {
      id: doc.id,
      name: data.name,
      email: data.email,
      createdAt: data.createdAt,
    };
  });
  return users;
};
