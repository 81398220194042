import { usePreview } from "react-dnd-preview";
const DraggablePreview = () => {
  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const { style } = preview;
  return (
    <div className="touch-draggable-preview" style={style}>
      <img src="/assets/syringe.png" alt="Syringe" />
    </div>
  );
};
export default DraggablePreview;
