import React from "react";

function Header() {
  return (
    <header className="header-container">
      <div>
        <img src="/assets/allergan-logo.png" alt="Allergan Logo" />
      </div>
      <div>
        <img src="/assets/volite-logo.png" alt="Volite Logo" />
      </div>
    </header>
  );
}

export default Header;
