import React from "react";
import { SyringeType } from "../utils/constants";
import { useDrop } from "react-dnd";

function Bubble({
  bubble,
  updateActiveState,
}: {
  bubble: string;
  updateActiveState: () => void;
}) {
  const [visible, setVisible] = React.useState(true);
  const [collectedProps, drop] = useDrop(
    () => ({
      accept: SyringeType,
      drop: () => {
        if (visible) {
          updateActiveState();
          setVisible(false);
        }
      },
    }),
    [visible]
  );
  return (
    <div className="bubble" ref={drop}>
      <img
        draggable="false"
        key={bubble}
        src={`/assets/${bubble}`}
        alt={bubble}
        className={visible ? "visible" : ""}
      />
    </div>
  );
}

export default Bubble;
