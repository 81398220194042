import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function Loader({ message }: { message: string }) {
  return (
    <section className="loader">
      <p>{message}</p>
      <PropagateLoader color="#4f1847" size={25} />
    </section>
  );
}
