import React, { useCallback, useEffect, useMemo, useState } from "react";
import { User } from "../utils/types";
import { getAllUsers } from "../utils/firebase-actions";
import Loader from "./Loader";

export default function Dashboard() {
  const [users, setUsers] = useState<User[] | null>(null);

  const loadUsers = useCallback(async () => {
    const users = await getAllUsers();
    setUsers(
      users.sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      })
    );
  }, []);
  useEffect(() => {
    loadUsers();
  }, []);

  const userData = useMemo(() => {
    return users?.map((user) => {
      return (
        <tr key={user.id}>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{new Date(user.createdAt).toLocaleString()}</td>
        </tr>
      );
    });
  }, [users]);
  return (
    <section className="dashboard-container">
      <h2>Dashboard</h2>
      {!users ? <Loader message={""} /> : ""}
      {users && !users?.length && (
        <p className="empty-message"> No Data To Show</p>
      )}
      {!!users?.length && (
        <table>
          <thead>
            <tr>
              <th>User Name</th>
              <th>Email</th>
              <th>Register Date</th>
            </tr>
          </thead>
          <tbody>{userData}</tbody>
        </table>
      )}
    </section>
  );
}
