import React, { useCallback, useEffect, useMemo } from "react";
import { bubbles, GAME_ENDING_TIMEOUT } from "../utils/constants";
import Syringe from "./Syringe";
import Bubble from "./Bubble";
import { useNavigate } from "react-router-dom";
function Game() {
  const navigate = useNavigate();
  const [activeState, setActiveState] = React.useState(0);

  useEffect(() => {
    if (activeState != 4) return;
    const timeOut = setTimeout(() => {
      navigate("/ending");
    }, GAME_ENDING_TIMEOUT);
    return () => {
      clearTimeout(timeOut);
    };
  }, [activeState]);

  const updateActiveState = useCallback(() => {
    setActiveState((activeState) => (activeState + 1) % 5);
  }, [activeState]);

  const activeImage = useMemo(() => {
    return activeState <= 1
      ? "one"
      : activeState == 2
      ? "two"
      : activeState == 3
      ? "three"
      : "four";
  }, [activeState]);

  return (
    <div className="game-container">
      <section className="model-container">
        <img
          draggable="false"
          src={`/assets/state_${activeImage}.png`}
          alt="Model"
        />
      </section>
      <section className="dropped-images-container">
        {bubbles.map((bubble) => (
          <Bubble
            key={bubble}
            bubble={bubble}
            updateActiveState={updateActiveState}
          />
        ))}
      </section>
      <Syringe activeState={activeState} />
    </div>
  );
}

export default Game;
