import React, { FormEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { addUser } from "../utils/firebase-actions";

function Login() {
  const [formData, setFormData] = React.useState({
    email: "",
    name: "",
  });
  const navigate = useNavigate();
  const submitAction = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const { email, name } = formData;
      if (email && name) {
        await addUser(name, email);
        navigate("/intro");
      }
    },
    [navigate, formData]
  );

  return (
    <div className="login-container">
      <form onSubmit={submitAction}>
        <input
          type="email"
          placeholder="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />
        <button>Submit</button>
      </form>
    </div>
  );
}

export default Login;
