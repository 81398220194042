import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

function Intro() {
  const navigate = useNavigate();
  const startAction = useCallback(() => {
    navigate("/game");
  }, [navigate]);

  return (
    <div className="intro-container">
      <img src="/assets/intro.png" alt="Game Guidelines" />
      <img
        src="/assets/intro_start.png"
        alt="Start Game"
        onClick={startAction}
      />
    </div>
  );
}

export default Intro;
