export const bubbles = [
  "longer.png",
  "hydrates.png",
  "nine_month.png",
  "deeper.png",
];

export const SyringeType = "SYRINGETYPE";

export const OUTRO_TIMEOUT = 7500;

export const GAME_ENDING_TIMEOUT = 1000;

export const COLLECTION_NAME = "VOLITE";
