import React from "react";
import { useDrag } from "react-dnd";
import { SyringeType } from "../utils/constants";

function Syringe({ activeState }: { activeState: number }) {
  const [{ isDragging }, drag, preview] = useDrag({
    type: SyringeType,
    item: {},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return (
    <section className="syringe-container">
      <img
        ref={drag}
        draggable="false"
        src="/assets/syringe.png"
        alt="Syringe"
        className={`syringe ${activeState === 4 ? "" : "visible"} ${
          isDragging ? "dragging" : ""
        }`}
      />
      <img
        draggable="false"
        src="/assets/drag.png"
        alt="dragging hint"
        className={`hint ${activeState === 4 ? "" : "visible"} `}
      />
    </section>
  );
}

export default Syringe;
