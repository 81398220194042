import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./Styles/main.scss";
import Header from "./Components/Header";
import Game from "./Components/Game";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import Outro from "./Components/Outro";
import Intro from "./Components/Intro";
import {
  Routes,
  BrowserRouter as Router,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import DraggablePreview from "./Components/DraggablePreview";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const isTouch = true;
const backend = isTouch ? TouchBackend : HTML5Backend;
root.render(
  <DndProvider backend={backend} options={{ enableMouseEvents: true }}>
    <Header />
    <Router>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/intro" element={<Intro />}></Route>
        <Route path="/game" element={<Game />}></Route>
        <Route path="/ending" element={<Outro />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </Router>
    {isTouch && <DraggablePreview />}
  </DndProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
